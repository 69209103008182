
.jumbled-letter {
    font-size: xx-large;
    display: inline-block;
    padding: 1em;
    border: 1px solid black;
    border-radius: 5px;
    margin: 0.5em;
  }
  
  @media(max-width: 500px) {
      .jumbled-letter {
          font-size: 15px;
      }
  }