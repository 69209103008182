body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

  
  
  /* @media (max-width: 1000px) {
    .game {
      width: 100vw
    }
  
    .card {
      width: 25vw
    }
  
    #grid-container {
      grid-template-columns: repeat(4, 25vw);
    }
  } */
  
  #grid-container {
    grid-template-columns: repeat(4, 10vw);
  }
  .card:hover {
    background: lightgrey
  }
  
  .card:disabled:hover {
    background: whitesmoke
  }
  .card {
    cursor: pointer;
    height: calc(10vw * 3/4);
    width: 10vw;
    border-radius: 5px;
    margin: auto;
  }
  .card:disabled {
    cursor: not-allowed;
  }
  
  .card:focus {
    outline: none;
  }
  
  #alert {
    animation-name: showAlert;
    animation-duration: 1.5s;
    animation-fill-mode: forwards; 
    padding: 0.5em;
    overflow: auto;
   top: 0;
    height: 100vh;
    width: 100%;
  }
  
  #alert-text {
    animation-name: showAlertText;
    animation-duration: 2.5s;
    animation-fill-mode: forwards; 
  }
  #playButton {
    width: 50%;
    border-radius: 5px;
    border: none;
    padding: 5px;
    bottom: 0;
  }
  
  @media (max-width: 1275px) {
    
  
    #alert-text button {
      position: relative;
      z-index: 3;
      
    }
  }
  
  @media (max-width: 1000px) {
    #alert-text h1 {
      font-size: x-large;
    }
  }
  @media (max-width: 800px) {
    #alert-text h1 {
      display: none;
    }
  }
  @keyframes showAlertText {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes showAlert {
    from {
      height: 0
    }
    to {
      
    }
  }
  
  @keyframes flip {
    0% {
      background:whitesmoke;
      transform: rotateX(180deg);
    }
    50% { 
  
    }
    100% {
      transform: rotateX(180deg) rotateX(180deg);
    }
  }
  
  @keyframes unflip {
    0% {
      border: 2px solid red;
    }
    50% { 
  
    }
    100% {
      border: 2px solid red;
      transform: rotateX(-180deg);
    }
  }
  
  
  
#result {
    animation: fadeIn 1s forwards;
    color: white;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8
    }
}

@media(max-width: 500px) {
    #grid-container {
        grid-template-columns: repeat(4, 75px);
    }
    .card {
        width: 75px;
        height: calc(75px * 3/4)
    }
}