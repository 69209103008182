nav {
    background: red;
    display: flex;
    height: max-content;
    color: white;
    justify-content: space-between;
}

#logo {
    color: inherit;
    text-decoration: none;
    display: block;
    font-size: x-large;
    padding: 0.75em;
}

#logo:hover {
    background: darkred;
    opacity: 0.8
}

#logo.active {
    background: darkred;
}
