#large-card {
    position: relative;
    display: inline-block;
    width: 400px;
    height: 400px;
    margin: 20px;
    border: 1px solid;
    border-radius: 10px;
    box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.09);
}

#message {
    position: absolute;
    top: -10px;
    left: 10px;
    background: red;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

#description {
    height: 100%;
}

@media(max-width: 500px) {
    #large-card {
        width: calc(100% - 40px);
    }
}