#game-container-parent {
    width: 100%;
    height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#game {
    border: 5px solid lightgray;
    padding: 1em;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    max-height: 80vh;
    max-width: 80vw;
    min-width: 33vw;
    position: relative;
    overflow: auto;
}

#game.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    background-color: white;
}

#game-container {
    border-radius: 10px;
    overflow: hidden;
    display: flex;

}

#game-text {
    
    height:inherit;
    padding: 10px;
    width: 25vh;
    background: black;
    color: white;
}

#game-text h2 {
    text-transform: uppercase;
}

#game-text p {
    color: lightgray
}

#cover {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    background: #333;
    opacity: 0.7;
    z-index: 5;
}

@media(max-width: 500px) {
    #game-container {
        width: 100%;
        display: unset;
    }
    #game {
        width: calc(100vw - 45px);
        max-width: calc(100vw -  45px);
        border: 5px solid lightgray;
        max-height: 67vh;
    }

    #game-text {
        width: 100%;
    }
}

#start {
    background: green;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-size: large;
    cursor: pointer;
}

@media (max-width: 500px) {
    #start {
        width: calc(100vw - 20px);
    }
}