#game-name {
    text-transform: uppercase;
    color: red;
    position: relative;
    /* display: inline-flex;
    justify-content: space-between; */
}


#new-game-content {
    height: 100%;
    width: 100%;
    background-size: cover;
    border-radius: 10px;
}


#new-game-text {
    width:40%;
    background-color: #333;
    right: 0;
    padding: 0 10px;
    position: absolute;
    height: 100%;
    color: white;
    opacity: 0.85;
    border-radius: 10px;
}

#new-game-text p {
    display: none
}

#new-game-content:hover #new-game-text p {
    display: block;
    line-height: 2em;
}
#new-game-text:hover {
    opacity: 0.9
}

#play {
    position: absolute;
    bottom: 2em;
    background: green;
    font-size: large;
    color: white;
    text-decoration: none;
    padding: 1em;
    text-transform: uppercase;
    border-radius: 10px;
}

#black {
    text-align: center;
    background: black;
    color: white;
    padding: 2em;
    margin: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 83%);
}

#header {
    margin: 0;
    background: darkred;
}

#tags {
    position: absolute;
    bottom: 0;
    opacity: 1;
    width: 100%;
    color: black;
    padding: 10px;
    border-radius: 10px;
}

.tag {
    display: inline-block;
    margin-left: 10px;
    background: green;
    color: white;
    padding: 7px;
    border-radius:33%;
}

#search {
    padding: 0.5em;
    font-size: large;
}