.hangmanbutton {
    background: firebrick;
    color: white;
    border-radius: 5px;
    display: inline-block;
    width: 45%;
    border: none;
    padding: 10px;
}

.option {
    margin-bottom: 20px;
    font-size: large;
}
.hangmanselect {
    width: 45%;
    overflow: hidden;
    padding: 10px;
    background: black;
    color: white;
    border-radius: 5px;
    margin-left:10px;
}

@media (max-width: 500px) {
    .hangmanbutton {
        width: calc(100vw - 50px)
    }

    .hangmanselect {
        display: none;
    }

    input {
        width: calc(100vw - 75px);
        padding: 10px;
    }
}

.character {
    width: 10px;
    height: 2px;
    background: black;
    margin-left: 5px;
    display: inline-block
}

.space {
    width: 15px;
    display: inline-block;
}